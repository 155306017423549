import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section';
import theme from '../theme/theme';
import AppContainer from '../components/Layout/AppContainer';

const pageQuery = graphql`
    query {
        file(relativePath: { eq: "pages/tos.md" }) {
            publicURL
            relativePath
            childMarkdownRemark {
                rawMarkdownBody
            }
        }
    }
`;

class Terms extends React.Component {
    public renderCover() {
        return (
            <Section narrow>
                <Text fontSize={['4xl']} m={[3, 5]}>
                    {'Legal'}
                </Text>
            </Section>
        );
    }

    public render() {
        return (
            <StaticQuery
                query={pageQuery}
                render={data => (
                    <AppContainer>
                        <Layout cover={this.renderCover()}>
                            {/* <CoverTitleContent */}
                            {/*     title={{ */}
                            {/*         children: data.file.childMarkdownRemark.frontmatter.title  */}
                            {/*     }} */}
                            {/*     subtitle={{ */}
                            {/*         children: <> */}
                            {/*             Last updated on {data.file.childMarkdownRemark.frontmatter.date} */}
                            {/*         </> */}
                            {/*     }} */}
                            {/*     text={{ */}
                            {/*         children: data.file.childMarkdownRemark.frontmatter.excerpt */}
                            {/*     }} */}
                            {/* /> */}
                            <Section>
                                <StyledContainer>
                                    <Box width={[1, 2 / 3]}>
                                        <StyledMarkdown
                                            children={
                                                data.file?.childMarkdownRemark
                                                    ?.rawMarkdownBody
                                            }
                                        />
                                    </Box>
                                </StyledContainer>
                            </Section>
                        </Layout>
                    </AppContainer>
                )}
            />
        );
    }
}

export default Terms;

const Headings = styled(Box)`
    margin: 2em 0;
    text-align: left;
    & > h3,
    h4,
    h5,
    h6 {
        font-family: ${theme.fonts.sansSerif};
    }
`;

const StyledContainer = styled(Box)`
    * {
        padding: 0.5em;
        line-height: 1.25;
        font-size: 11pt;
        font-family: 'Open Sans', sans-serif;
        color: silver;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
`;

const StyledMarkdown = styled(ReactMarkdown)`
    color: ${theme.colors.silver};

    & a {
        color: ${theme.colors.brand.accent.red};
    }
`;
